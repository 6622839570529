var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.legends.length > 0)?_c('div',{staticClass:"legend-pane"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._l((_vm.legends),function(item,index){return _c('div',{key:index,staticClass:"legends-box"},[_c('img',{attrs:{"src":"/imgs/map/sitetype3.png","alt":""}}),_c('span',{staticClass:"legend-name"},[_vm._v(_vm._s(item.name))])])}),_c('div',{staticClass:"left-top-corner"}),_c('div',{staticClass:"right-top-corner"}),_c('div',{staticClass:"right-bottom-corner"}),_c('div',{staticClass:"left-bottom-corner"})],2):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legends-box"},[_c('span',{staticStyle:{"display":"inline-block","width":"24px","height":"20px","border-radius":"4px","background":"#5490D5"}}),_c('span',{staticClass:"legend-name"},[_vm._v("西北地区")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legends-box"},[_c('span',{staticStyle:{"display":"inline-block","width":"24px","height":"20px","border-radius":"4px","background":"#2E77AF"}}),_c('span',{staticClass:"legend-name"},[_vm._v("西南地区")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legends-box"},[_c('span',{staticStyle:{"display":"inline-block","width":"24px","height":"20px","border-radius":"4px","background":"#49A3DF"}}),_c('span',{staticClass:"legend-name"},[_vm._v("华北地区")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legends-box"},[_c('span',{staticStyle:{"display":"inline-block","width":"24px","height":"20px","border-radius":"4px","background":"#1A68AA"}}),_c('span',{staticClass:"legend-name"},[_vm._v("东北地区")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legends-box"},[_c('span',{staticStyle:{"display":"inline-block","width":"24px","height":"20px","border-radius":"4px","background":"#6FBCFF"}}),_c('span',{staticClass:"legend-name"},[_vm._v("华中地区")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legends-box"},[_c('span',{staticStyle:{"display":"inline-block","width":"24px","height":"20px","border-radius":"4px","background":"#3685E0"}}),_c('span',{staticClass:"legend-name"},[_vm._v("华东地区")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legends-box"},[_c('span',{staticStyle:{"display":"inline-block","width":"24px","height":"20px","border-radius":"4px","background":"#3B64C2"}}),_c('span',{staticClass:"legend-name"},[_vm._v("华南地区")])])
}]

export { render, staticRenderFns }