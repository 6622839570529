<template>
  <div class="legend-pane" v-if="legends.length > 0">
    
    <div class="legends-box">
      <span style="display:inline-block;width:24px;height:20px;border-radius:4px;background:#5490D5"></span>
      <span class="legend-name">西北地区</span>
    </div>
    <div class="legends-box">
      <span style="display:inline-block;width:24px;height:20px;border-radius:4px;background:#2E77AF"></span>
      <span class="legend-name">西南地区</span>
    </div>
    <div class="legends-box">
      <span style="display:inline-block;width:24px;height:20px;border-radius:4px;background:#49A3DF"></span>
      <span class="legend-name">华北地区</span>
    </div>
    <div class="legends-box">
      <span style="display:inline-block;width:24px;height:20px;border-radius:4px;background:#1A68AA"></span>
      <span class="legend-name">东北地区</span>
    </div>
    <div class="legends-box">
      <span style="display:inline-block;width:24px;height:20px;border-radius:4px;background:#6FBCFF"></span>
      <span class="legend-name">华中地区</span>
    </div>
    <div class="legends-box">
      <span style="display:inline-block;width:24px;height:20px;border-radius:4px;background:#3685E0"></span>
      <span class="legend-name">华东地区</span>
    </div>
    <div class="legends-box">
      <span style="display:inline-block;width:24px;height:20px;border-radius:4px;background:#3B64C2"></span>
      <span class="legend-name">华南地区</span>
    </div>
    <div v-for="(item, index) in legends" :key="index" class="legends-box">
      <img src="/imgs/map/sitetype3.png" alt="" />
      <span class="legend-name">{{ item.name }}</span>
      <!-- <span class="legend-num">{{ item.num }}</span> -->
    </div>
    <div class="left-top-corner"></div>
    <div class="right-top-corner"></div>
    <div class="right-bottom-corner"></div>
    <div class="left-bottom-corner"></div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    currentFarm: Object,
  },
  watch: {
    currentFarm(n) {
      this.getLegends();
    },
  },
  data() {
    return {
      legends: [],
    };
  },
  methods: {
    getLegends() {
      this.$post(this.$api.LEGEND.DEVICE_LEGEND, {
        siteType: "3",
        farmId: this.currentFarm ? this.currentFarm.sourceId : "",
      }).then((res) => {
        this.legends = res;
      });
    },
  },
  mounted() {
    this.getLegends();
  },
};
</script>

<style lang="less" scoped>
.legend-pane {
  width: 100%;
  // max-height: 200px;
  padding: 16px;
  overflow: auto;
  // background: rgba(23, 34, 60, 0.6);
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &::-webkit-scrollbar {
    display: none;
  }
  .legends-box {
    display: flex;
    align-items: center;
    color: #fff;
    &:not(:last-child) {
      padding-bottom: 10px;
    }
    padding-right: 20px;
    img {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      flex-grow: 0;
    }
    .legend-name {
      padding-left: 10px;
      flex-shrink: 0;
      flex-grow: 0;
    }
    .legend-num {
      flex-shrink: 1;
      flex-grow: 1;
      text-align: right;
    }
  }
  .left-top-corner {
    width: 18px;
    height: 10px;
    border-top: 2px solid rgba(0, 144, 255, 1);
    border-left: 2px solid rgba(0, 144, 255, 1);
    position: absolute;
    left: 0px;
    top: 0px;
  }

  .right-top-corner {
    width: 18px;
    height: 10px;
    border-top: 2px solid rgba(0, 144, 255, 1);
    border-right: 2px solid rgba(0, 144, 255, 1);
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .right-bottom-corner {
    width: 18px;
    height: 10px;
    border-bottom: 2px solid rgba(0, 144, 255, 1);
    border-right: 2px solid rgba(0, 144, 255, 1);
    position: absolute;
    right: 0px;
    bottom: 0px;
  }

  .left-bottom-corner {
    width: 18px;
    height: 10px;
    border-bottom: 2px solid rgba(0, 144, 255, 1);
    border-left: 2px solid rgba(0, 144, 255, 1);
    position: absolute;
    left: 0px;
    bottom: 0px;
  }
}
</style>